/* eslint-disable check-file/filename-blocklist */

// We have deprecated emotion. Please use compiled instead
// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import { css } from '@emotion/core';
import { defineMessages } from 'react-intl-next';
import type { IntlShape } from 'react-intl-next';

import { token } from '@atlaskit/tokens';

const messages = defineMessages({
	contentRendererInlineCommentMarkerStart: {
		id: 'content-renderer.inlineComment.marker.start',
		defaultMessage: 'inline comment start',
		description:
			'Starting marker to indicate that text is highlighted with an inline comment by a screen reader.',
	},
	contentRendererInlineCommentMarkerEnd: {
		id: 'content-renderer.inlineComment.marker.end',
		defaultMessage: 'inline comment end',
		description:
			'Ending marker to indicate that text was highlighted with an inline comment by a screen reader.',
	},
});

/* Yellow highlights*/
const highlightStyle = (
	intl: IntlShape,
	isFabric?: boolean,
	// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Ignored via go/DSP-18766
) => css`
	cursor: pointer;
	background-color: ${isFabric
		? token('color.background.accent.yellow.subtlest')
		: token('color.background.accent.yellow.subtler')};
	border-bottom: 2px solid ${token('color.border.accent.yellow')};
	&.active-highlight {
		background-color: ${token('color.background.accent.yellow.subtle')};
		border-bottom: 2px solid ${token('color.border.accent.yellow')};
		box-shadow: ${token('elevation.shadow.overlay')};
	}

	/* Accessibility markers for screen readers: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/mark#accessibility_concerns */
	&::before,
	&::after {
		clip-path: inset(100%);
		clip: rect(1px, 1px, 1px, 1px);
		height: 1px;
		overflow: hidden;
		position: absolute;
		white-space: nowrap;
		width: 1px;
	}

	&.first-mark-highlight::before {
		content: ' [${intl.formatMessage(messages.contentRendererInlineCommentMarkerStart)}] ';
	}

	&.last-mark-highlight::after {
		content: ' [${intl.formatMessage(messages.contentRendererInlineCommentMarkerEnd)}] ';
	}
`;

/* During SSR we add dynamic css targeting markerRefs directly with attribute span[data-id="markerRef"]
During SPA our current logic of adding inline-highlight class takes over*/
export const inlineHighlightStyles = (markerRefs, isFabric, intl: IntlShape) => {
	// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
	if (process.env.REACT_SSR) {
		const ssrHighlightStyles = {};
		markerRefs.forEach((markerRef) => {
			const attribute = isFabric ? 'id' : 'ref';
			const selector = isFabric
				? `mark[data-${attribute}="${markerRef}"]`
				: `span[data-${attribute}="${markerRef}"]`;

			ssrHighlightStyles[selector] = highlightStyle(intl, isFabric);
		});
		return ssrHighlightStyles;
	} else {
		return inlineCommentStyles(intl);
	}
};

// eslint-disable-next-line @atlaskit/design-system/no-css-tagged-template-expression -- Ignored via go/DSP-18766
export const inlineCommentStyles = (intl: IntlShape) => css`
	div[mode='stick'] {
		z-index: 1;
		background: ${token('color.background.inverse.subtle')};
	}

	span {
		&.inline-highlight {
			${highlightStyle(intl)}
		}

		&.inline-create-comment-selection {
			background-color: ${token('color.background.accent.yellow.subtle')};
			border-bottom: 2px solid ${token('color.border.accent.yellow')};
			box-shadow: ${token('elevation.shadow.overlay')};
		}
	}
`;
