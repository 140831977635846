import type { RefObject } from 'react';
import { useCallback, useEffect } from 'react';

import { useRouteActions } from '@confluence/route-manager/entry-points/RouteState';
import { useATLGeneralWebPanelListener } from '@confluence/web-panel-location';

import { getHashLinkClickOptions } from './getHashLinkClickOptions';
import { scrollToElement } from './scrollToElement';

export type UseHashLinkScrollHandlerOptions = {
	/**
	 * The container of anchor elements. Provide this property to limit the scrolling behavior to a certain element.
	 * Use React.RefObject for this.
	 */
	anchorContainer: RefObject<HTMLDivElement>;
	/**
	 * Specifying this value will allow you to correct scroll positioning.
	 * For example, if you have a fixed-positioned element that overlaps your scroll area,
	 * and that element has a height of `60` pixels, you'll set `scrollCorrectionPixels` to `-60`.
	 */
	scrollCorrectionPixels?: number;
};

/**
 * A helper component for making scrolling to document anchors easier.
 *
 * ```js
 *  const Content = () => {
 *    const containerRef = useRef<HTMLDivElement>(null);
 *    const topOffset = useTopOffset();
 *
 *    useHashLinkScrollHandler({
 *      anchorContainer: containerRef,
 *      scrollCorrectionPixels: -topOffset
 *    });
 *
 *    return (
 *      <div ref={containerRef}>
 *        <h1 id="top">This is document top</h1>
 *        <br /><br /><br /><br /><br />
 *        <a href="#top">Link to top</a>
 *      </div>
 *    )
 *  }
 * ```
 */
export const useHashLinkScrollHandler = ({
	anchorContainer,
	scrollCorrectionPixels,
}: UseHashLinkScrollHandlerOptions): void => {
	const { setHash } = useRouteActions();
	const { isATLGeneralWebPanelFixed, atlGeneralWebPanelHeight } = useATLGeneralWebPanelListener();

	const fixedWebPanelHeight = isATLGeneralWebPanelFixed ? atlGeneralWebPanelHeight : 0;

	const onClickListener = useCallback(
		(e: MouseEvent) => {
			if (!anchorContainer.current) {
				return;
			}
			if (e.defaultPrevented) {
				// there is explicit handling of this event somewhere else, and default handling was prevented.
				return;
			}

			const options = getHashLinkClickOptions(e, anchorContainer.current);
			if (options) {
				const { anchorElement, hash, hasTableParent } = options;
				e.preventDefault();
				let pixelAdjustment = scrollCorrectionPixels;

				// check if element is nested inside table, if so we need to account for
				// sticky table header overlapping the element
				if (hasTableParent && pixelAdjustment) pixelAdjustment = pixelAdjustment * 2.25;

				scrollToElement(anchorElement, (scrollCorrectionPixels || 0) - fixedWebPanelHeight);
				setHash(hash);
			}
		},
		[anchorContainer, scrollCorrectionPixels, setHash, fixedWebPanelHeight],
	);

	useEffect(() => {
		window.addEventListener('click', onClickListener);

		return () => {
			window.removeEventListener('click', onClickListener);
		};
	}, [onClickListener]);
};
